import React from 'react';

import AppInputPassword from 'primitives/input/appInputPassword';
import { FormikProvider } from 'formik';
import { isDev } from 'appEnv';
import { changePassword } from 'appAPI';
import { getFrontMessage } from '../../../appMessageDefinitions';
import { useAppFormik } from '../../../primitives/input/formikUtils';
import { AppButton } from '../../../primitives/appButton';
import { ShText } from '@shoootin/translations';
import { FormError } from '../../../primitives/input/formError';
import { CurrentUserStorage } from '../../../appStorage';
import { redirectCurrentUserAfterAuth } from '../../../appAuth';

export type NewPasswordValues = {
  password: string;
  confirmpassword: string;
  token: string;
};

const debug = false;

const EmptyNewPasswordValuesForm = {
  password: isDev && debug ? '00000001' : '',
  confirmpassword: isDev && debug ? '00000001' : '',
  token: isDev ? 'e040b458-cb26-4188-8b98-2b2ec4fdad5f' : '',
};

const NewPasswordForm = ({ token = '' }: { token: string }) => {
  const { form, globalError } = useAppFormik({
    initialValues: { ...EmptyNewPasswordValuesForm, token },
    submitAppForm: async values => {
      const { user } = await changePassword(values);
      CurrentUserStorage.set(user);
      await redirectCurrentUserAfterAuth(user);
    },
  });
  return (
    <FormikProvider value={form}>
      <div>
        <div className="form-section half">
          {globalError && <FormError error={globalError} />}
          <div className="field-row">
            <span>
              <AppInputPassword
                form={form}
                name="password"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_password_PlaceHolder',
                )}
                modifier="large"
              />
            </span>
          </div>
          <div className="field-row">
            <span>
              <AppInputPassword
                form={form}
                name="confirmpassword"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_confirmPassword_PlaceHolder',
                )}
                modifier="large"
              />
            </span>
          </div>

          <div className="field-row space">
            <AppButton modifier="large" onClick={() => form.submitForm()}>
              <ShText message={'common_actions_validate'} />
            </AppButton>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};

export default NewPasswordForm;
