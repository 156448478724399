import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import PatternSvg from 'components/svg/patternSvg';
import ChevronPrevSvg from 'components/svg/picto/chevronPrevSvg';
import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import NewPasswordForm from './components/newPasswordForm';
import { ShText } from '@shoootin/translations';
import { defineMessages } from 'react-intl';
import { getFrontMessage } from '../../appMessageDefinitions';
import { usePageParams } from '../../layout/appContext';
import { ShSpinner } from '@shoootin/components-web';

const prefix = 'front_newPassword';

const messages = defineMessages({
  pageTitle: {
    id: `${prefix}_pageTitle`,
    defaultMessage: 'New password',
  },
});

const NewPasswordPageContent = ({ token }: { token: string }) => {
  return (
    <>
      <AppSection
        className="login"
        header={
          <H2>
            <ShText message={messages.pageTitle} tagName="span" />
            <PatternSvg />
          </H2>
        }
      >
        <NewPasswordForm token={token} />
        <AppInternalLink className="login-link" to={AppInternalPaths.home}>
          <ChevronPrevSvg />{' '}
          <ShText message={getFrontMessage('common_actions_cancel')} />
        </AppInternalLink>
      </AppSection>
    </>
  );
};

const NewPassword = () => {
  const { token } = usePageParams();
  return (
    <MainLayout
      className="page-login light"
      showContact={false}
      pageName="newPassword"
    >
      {token ? <NewPasswordPageContent token={token} /> : <ShSpinner />}
    </MainLayout>
  );
};

export default NewPassword;
